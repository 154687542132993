app.directive('clickAnywhereButHere', ['$document', function ($document) {
    return {
        restrict: 'A',
        link: function (scope, elem, attr, ctrl) {
            var elemClickHandler = function (e) {
                e.stopPropagation();
            };

            var docClickHandler = function () {
                
                if (scope.$root != null && scope.$root != undefined)
                {
                    if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest')
                        scope.$apply(attr.clickAnywhereButHere);
                };
            };

            elem.on('click', elemClickHandler);
            $document.on('click', docClickHandler);

            // teardown the event handlers when the scope is destroyed.
            scope.$on('$destroy', function () {
                elem.off('click', elemClickHandler);
                $document.off('click', docClickHandler);
            });
        }
    }
}]);